<template>
  <base-page>
    <el-card body-style="padding:10px;" style="margin: 5px 0px 5px 0px; ">
      <!-- <el-collapse accordion style="border: none"> -->
        <!-- <el-collapse-item name="1" style="border: none"> -->
          <!-- <template slot="title"> -->
            <!-- <div slot="header" class="clearfix"> -->
              <titlu-pagina
                Titlu="Registru casa"
                @on_add_clicked="show_dialog()"
                :AdaugaVisible="true"
                
              />
            <!-- </div> -->
          <!-- </template> -->
          <div class="filtre">
            <el-form
            :rules="rules"
              @submit.prevent.native="refresh_info()"
              ref="my-form"
              :model="Filters"
            >
              <el-row style="margin: 0px; height: 60px;" class="input-group">
                <el-col class="input-element">
                  <el-form-item prop="Tip" label="Tip" size="mini">
                    <el-select size="mini" class="full-width" v-model="Filters.Tip" filterable @change="refresh_info()">
                        <el-option label="Toate" value="-1"/>
                        <el-option label="Incasare" value="Intrare"/>
                        <el-option label="Plata" value="Iesire"/>
                      </el-select>
                  </el-form-item>
                </el-col>
                <el-col class="input-element">
                  <el-form-item label="Interval" size="mini" prop="Data">
                    <el-date-picker size="mini" 
                      class="full-width" 
                      :clearable="false"
                      v-model="Filters.Data" 
                      type="daterange" @change="refresh_info()"
                      range-separator="-" start-placeholder="Data inceput" end-placeholder="Data sfarsit"
                      :picker-options="datePickerOptions" format="dd/MM/yyyy" value-format="yyyy-MM-dd">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col class="input-element">
                  <el-form-item label="Nr. document" size="mini">
                    <el-input size="mini" class="full-width" v-model="Filters.NrDocument" @input="refresh_info()"/>
                  </el-form-item>
                </el-col>
                <el-col class="input-element">
                  <el-form-item label="Explicatie" size="mini">
                    <el-input size="mini" class="full-width" v-model="Filters.Explicatie" @input="refresh_info()"/>
                  </el-form-item>
                </el-col>
                <!-- <el-col class="input-element">
                  <el-form-item label="Valoare Min" size="mini">
                    <el-input-number size="mini" class="full-width" v-model="Filters.ValoareMin" :precision="0" :max="999999" :min="0"/>
                  </el-form-item>
                </el-col>
                <el-col class="input-element">
                  <el-form-item label="Valoare Max" size="mini">
                    <el-input-number size="mini" class="full-width" v-model="Filters.ValoareMax" :precision="0" :max="999999" :min="0"/>
                  </el-form-item>
                </el-col> -->
              </el-row>
              <!-- <el-row style="margin: 10px" class="input-group">
                <el-col style="width: 100%">
                  <div class="footer-form">
                    <el-button size="mini"
                      type="primary"
                      native-type="submit"
                      @click="refresh_info()"
                    >
                      Aplica
                    </el-button>
                    <el-button size="mini"
                      type="danger"
                      native-type="submit"
                      @click="reset()"
                    >
                      Reseteaza
                    </el-button>
                  </div>
                </el-col>
                </el-row> -->
                <!-- <el-row v-if="this.SoldInitial !=''" style="margin: 1px">
                  <el-col style="text-align: right;">
                    <span >Sold initial: {{this.SoldInitial}} RON</span>
                  </el-col>
                </el-row> -->
            </el-form>
          </div>
        <!-- </el-collapse-item> -->
      <!-- </el-collapse> -->
    </el-card>

    <el-card body-style="padding:10px;" style="margin: 5px 0px 5px 0px">
      <el-row v-if="this.SoldInitial >'-1'" style="margin: -5px 0px -5px 0px">
        <el-col style="text-align: right;">
          <span >Sold initial: {{this.SoldInitial}} RON</span>
        </el-col>
      </el-row>
    </el-card>
    <el-table :header-cell-style="{ background: '#393E46' }" el-table :data="Results" size="mini" :cell-style="{padding: '1px', height: '17px'}">
      <el-table-column prop="Id" label="#"></el-table-column>
      <el-table-column label="Data">
        <template slot-scope="scope">
          {{ scope.row.Data  | Data }}
        </template>
      </el-table-column>
      <el-table-column prop="NrDocument" label="Nr. Document"/>
      <el-table-column prop="Explicatie" label="Explicatie"/>
      <el-table-column prop="Tip" label="Tip">
        <template slot-scope="scope">
          <span  v-if="scope.row.Tip == 'Iesire'" >Plata </span>
          <span  v-else >Incasare</span>
        </template>
      </el-table-column>
      <el-table-column label="Suma" align="right">
        <template slot-scope="scope">
          <span  v-if="scope.row.Tip == 'Iesire'"  style="color:red;font-weight: bold;">- {{ scope.row.Suma  | format_money }} </span>
          <span  v-else style="font-weight: bold;"> {{ scope.row.Suma  | format_money }} </span>
        </template>
      </el-table-column>
     
      <el-table-column
        align="right"
        fixed="right"
        label="Actiuni"
        width="100px"
      >
        <template  v-if="scope.row.IdIncasare==null" slot-scope="scope">
          <el-tooltip content="Modificare">
            <el-button class="butonReg"
              :disabled="scope.row.LunaBlocata"
              type="primary"
              icon="el-icon-edit"
              circle size="mini"
              @click="show_dialog(scope.row.Id)"
            />
          </el-tooltip>
          <!-- :disabled="Boolean(Number(scope.row.Sterge))" -->
          <el-tooltip v-if="scope.row.IdIncasare==null" content="Sterge">
            <el-button class="butonReg"
              :disabled="scope.row.LunaBlocata"
              type="danger"
              icon="el-icon-delete"
              circle size="mini"
              
              @click="delete_item(scope.row)"
            />
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <el-card body-style="padding:10px;" style="margin: 5px 0px 5px 0px">
      <el-row v-if="this.SoldInitial >'-1'" style="margin: -5px 0px -5px 0px">
        <el-col style="text-align: right;">
          <span >Sold final: {{this.SoldFinal}} RON  </span>
        </el-col>
      </el-row>
    </el-card>

    <!-- <el-pagination
      @size-change="refresh_info"
      @current-change="refresh_info"
      :page-size.sync="PaginationInfo.PerPage"
      :current-page.sync="PaginationInfo.Page"
      :total="PaginationInfo.RowCount"
      layout="pager"
    /> -->
    <Registru-casa-dialog ref="dlg" @save="refresh_info()" />
  </base-page>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import Registru_casa_dialog from "@/pages/registru_casa/Registru_casa_dialog.vue";
import TitluPagina from "@/widgets/TitluPagina";
import moment from "moment";

export default {
  name: "registru_casa",
  extends: BasePage,
  components: {
    "base-page": BasePage,
    "Registru-casa-dialog": Registru_casa_dialog,
    "titlu-pagina": TitluPagina,
  },
  data() {
    return {
      rules: {
        Data: [{ required: true, message: "Campul este obligatoriu" }],
      },
      datePickerOptions: settings.datePickerOptions,
      Results: [],
      SoldInitial: '',
      SoldFinal: '',
      base_url: "",
      Info: {
        // clienti: [],
      },
      Filters: {
        Tip: "-1",
        Data: [moment().startOf('month').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
        NrDocument: "",
        Explicatie: "",
        ValoareMin: "0",
        ValoareMax: "0",
      },
      OrderBy: {},
      PaginationInfo: {
        Page: 1,
        PerPage: 50,
        RowCount: 0,
        PageSizes: [10, 25, 50, 100, 200],
      },
    };
  },
  methods: {
    async get_info() {
      var response = await this.post("registru_casa/get_info");
      // this.Info.clienti = response.clienti;
      // if (this.Filters.IdClient.length > 0)
      //   this.Filters.IdClient = this.Info.clienti[0].Id;
      this.refresh_info();
    },

    async refresh_info() {


      this.$refs["my-form"].validate(async (valid) => {
        if (valid) {
          var response = await this.post("registru_casa/index", {
              Filters: this.Filters,
              OrderBy: this.OrderBy,
              // PaginationInfo: this.PaginationInfo,
            });
            this.Results = response.Results;
            this.SoldInitial = response.SoldInitial;
            this.SoldFinal = response.SoldFinal;

         
          
        }
      });


   
      this.select_menu_item("nomenclatoare");
    },
    reset() {
      this.Filters = {
        Tip: "-1",
        Data: [moment().startOf('month').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
        NrDocument: "",
        Explicatie: "",
        ValoareMin: "0",
        ValoareMax: "0",
      };
      this.refresh_info();
    },

    async delete_item(item) {
      var confirm = await this.$confirm(`Sunteti sigur ?`, "Warning");
      if (confirm) {
        await this.post("registru_casa/delete_item", { id: item.Id });
        this.refresh_info();
      }
    },

    show_dialog(id) {
      this.$refs.dlg.show_me(id);
    },
  },
  mounted() {
    // if (!this.$has_right('Management') && !this.$has_right('Supervizor')){
    //   this.$router.push("/no-rights");
    //   return;
    // }
    this.base_url = settings.BASE_URL;
    this.get_info();
  },
};
</script>

<style lang="less" scoped>
.top50 {
  margin-top: 20px;
}

.input-element {
  width: 23%;
  margin-right: 2%;
}

@media only screen and (min-width: 768px) {
  .filtre {
    display: flex;
    grid-template-columns: none;
  }
  .el-table tr {
    word-break: break-word;
    // font-size: 12px !important;
  }
  .footer-form {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
}

.butonReg {
  font-size: 8px;
}
.filtre {
  width: 100% !important;
  display: grid;
  grid-template-columns: repeat(2);
 

  // grid-auto-rows: 100px;
  .el-input-number {
    width: 100% !important;
  }
}
</style>
