<template>
  <el-dialog title="Registru casa" :visible.sync="showPopup" id="popup_dialog" width="90%" class="my-dialog-class" append-to-body>
    <el-form
      label-position="top"
      :inline="false"
      :rules="rules"
      label-width="100%"
      :model="selectedObject"
      ref="my-form"
      @submit.prevent="save"
      v-loading="loadingVisible"
    >
      <el-row :gutter="15">
        <el-col :md="6">
          <el-form-item prop="Data" label="Data">
            <el-date-picker ref="focusMe" size="mini" :picker-options="datePickerOptions" class="full-width" v-model="selectedObject.Data" type="date"
            @change="handleSelectData" value-format="yyyy-MM-dd" format="dd.MM.yyyy" />
          </el-form-item>
        </el-col>
        <el-col :md="6">
          <el-form-item prop="NrDocument" label="Nr. Document">
            <el-input size="mini" class="full-width" v-model="selectedObject.NrDocument" />
          </el-form-item>
        </el-col>
        <el-col :md="6">
          <el-form-item prop="Suma" label="Suma">
            <el-input-number size="mini" class="full-width" v-model="selectedObject.Suma" 
              :precision="2" :min="0" :step="0.01"  />
          </el-form-item>
        </el-col> 
        <el-col :md="6">
          <el-form-item prop="Tip" label="Tip">
            <el-select size="mini" class="full-width" v-model="selectedObject.Tip" filterable>
                <el-option label="Incasare" value="Intrare"/>
                <el-option label="Plata" value="Iesire"/>
              </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item prop="Explicatie" label="Explicatie">
            <el-input size="mini" placeholder="Explicatie" type="textarea"  class="full-width" v-model="selectedObject.Explicatie" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="showPopup = false"> Renunta </el-button>
      <el-button type="primary" @click="save"> Salveaza </el-button>
    </span>
  </el-dialog>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import moment from "moment";

export default {
  name: "Registru_casa_dialog",
  extends: BasePage,
  components: {},
  data() {
    return {
      datePickerOptions: {firstDayOfWeek: 1,},
      baseUrl: "",
      showPopup: false,
      mode: "add",
      selectedObject: {
        Id: "",
        Nume: "",
        Act: "",
        CNP: "",
        Suma: "",
      },
      Info: {
        // clienti: [],
      },
      rules: {
        Data: [{ required: true, message: "Campul este obligatoriu" }],
        NrDocument: [{ required: true, message: "Campul este obligatoriu" }],
        Suma: [{ required: true, message: "Campul este obligatoriu" }],
        Tip: [{ required: true, message: "Campul este obligatoriu" }],
      },
    };
  },
  methods: {
    show_me: async function (id) {
      this.selectedObject = {
        Id: "",
        Nume: "",
        Act: "",
        CNP: "",
      };
      this.showPopup = true;
      if (id == null) {
        this.mode = "add";
        this.$nextTick(()=> {
          this.$refs['focusMe'].focus();
          // this.selectedObject.Data = moment().format('YYYY-MM-DD');
        })
      } else {
        this.mode = "edit";
        var result = await this.post("registru_casa/get_info_item_dialog", {
          id: id,
        });
        this.selectedObject = result.Item;
      }
    },

    async get_info() {},
    async handleSelectData() {

      var result = await this.post("luni_blocate/verifica_data_intrare", { data: this.selectedObject.Data });
      if(result.StatusLuna == 0){
        var data =  moment(this.selectedObject.Data);
        var mesaj =  'Luna ' + this.$options.filters.LunaLitere(data.format('M')) + ' ' +  data.format('Y') + ' este inchisa!';
        this.$notify({
            title: "Atentie",
            message: mesaj,
            dangerouslyUseHTMLString: true,
            type: "warning",
            duration: 5000,
          })

        this.selectedObject.Data = '';
        this.$nextTick(()=> {
          this.$refs['focusMe'].focus();
        })

      }

    },
    save: async function () {
      this.$refs["my-form"].validate(async (valid) => {
        if (valid) {
          let result = await this.post("registru_casa/save", {
            mode: this.mode,
            object: this.selectedObject,
          });
          this.showPopup = false;
          this.$emit("save", result.obj);
        }
      });
    },
    focusChanged (event) {
      const allElements = document.querySelectorAll('*');
      allElements.forEach((element) => {
        element.classList.remove('selectat');
      });

      const el = event.target
      el.classList.add("selectat");
    }
  },
  mounted: function () {
    this.baseUrl = settings.BASE_URL;
    this.get_info();
  },
  created() {
    document.addEventListener('focusin', this.focusChanged)
  },
  beforeDestroy() {
    document.removeEventListener('focusin', this.focusChanged)
  },
};
</script>

<style lang="less" scoped>
.full-width {
  width: 100%;
}

/deep/ .el-checkbox__input.is-focus{
  border: 3px solid yellow !important;
  border-radius: 5px;
}
/deep/ .selectat{
  background-color: yellow !important;
}
</style>