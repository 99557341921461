import { render, staticRenderFns } from "./Registru_casa_dialog.vue?vue&type=template&id=626e2ff9&scoped=true&"
import script from "./Registru_casa_dialog.vue?vue&type=script&lang=js&"
export * from "./Registru_casa_dialog.vue?vue&type=script&lang=js&"
import style0 from "./Registru_casa_dialog.vue?vue&type=style&index=0&id=626e2ff9&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "626e2ff9",
  null
  
)

export default component.exports